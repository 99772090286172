import * as React from "react"
import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default function Bio() {
  return (
    <>
      Jmenuji se <Styled.a href="https://twitter.com/maximovby">Alexandr Maximov</Styled.a> a jsem softwarový inženýr.
      <br />
      Pomáhám spojit firmy a IT odborníky.
    </>
  )
}
